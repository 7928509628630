<template>
    <v-container>
        <v-row>
            <v-col cols="8" offset="2">

                <v-card v-if="isError">
                    <v-card-title>{{ $t('error') }}</v-card-title>

                    <v-card-text>
                        <p>{{ $t('no_database') }} </p>
                    </v-card-text>
                </v-card>

                <v-card v-else>
                    <v-card-title>{{ base.home_header }}</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col>
                                <div v-html="base.home_text"></div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'Home',

        computed: {
            ...mapState(['base', 'isLoggedIn', 'isError']),
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        },

        data() {
            return {
            }
        },
    }
</script>

